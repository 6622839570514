
// App.js
import React from 'react';
import Routes from "./routes/routes";
import AuthProvider from './provider/AuthProvider';

const App = () => {
  return (
    <AuthProvider>
   <Routes />
    </AuthProvider>
  );
};

export default App;
