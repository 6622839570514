import React from "react";
import Header from "../Reusablecomponents/Header";
import Footer from "../Reusablecomponents/Footer";
import LuxuryVilaimg from "../../assets/images/LuxuryVila.png";
import { PRIMARY_COLOR } from "../../constant";
import { PRIMARY_COLOR2 } from "../../constant";
import { RiArrowDropDownLine } from "react-icons/ri";
import Navbar from "../Reusablecomponents/Navbar";
import { MdCalendarMonth } from "react-icons/md";

import OwnerHomeImg5 from "../../assets/images/OwnerHome5.png";
import OwnerHomeImg6 from "../../assets/images/OwnerHome6.png";
import OwnerHomeImg7 from "../../assets/images/OwnerHome7.png";
import OwnerHomeImg8 from "../../assets/images/OwnerHome8.png";
import OwnerHomeImg9 from "../../assets/images/OwnerHome9.png";
import OwnerHomeImg10 from "../../assets/images/OwnerHome10.png";
import SearchBarWithDropdown2 from "../ShadCN/SearchBarWIthDropdownStates2";
const LuxuryList = () => {
  const luxuryList = [
    {
      cover_image: LuxuryVilaimg,
      property_name: "Villa Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Mia",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: LuxuryVilaimg,
      property_name: "Vila Corsa",
      distance: "5km",
      price: 4260,
      room: 4,
      guests: 4,
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
  ];
  const luxuryList1 = [
    {
      cover_image: OwnerHomeImg5,
    },
    {
      cover_image: OwnerHomeImg6,
    },
    {
      cover_image: OwnerHomeImg7,
    },
    {
      cover_image: OwnerHomeImg8,
    },
    {
      cover_image: OwnerHomeImg9,
    },
    {
      cover_image: OwnerHomeImg10,
    },
  ];
  return (
  <div style={{
    backgroundColor:PRIMARY_COLOR,
  }}>
    <Navbar />
      <div
      style={{
        height:"auto",
       width:"95vw"
      }}
    >
       <SearchBarWithDropdown2/>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-around items-center w-full max-w-7xl mx-auto"
        style={{
          //width: "95vw",
          // paddingLeft:"10vw",
          // paddingRight:"10vw",
          gap:"20px"
        }}
      >
        {luxuryList.map((project, index) => (
          <div
            style={{
              padding: "5px",
            }}
            //onClick={() => HandleClick(project.slug)}
            key={index}
          >
            <div>
              <img
                style={{
                  borderRadius: "10px",
                }}
                src={project.cover_image}
                alt={project.property_name}
              />
            </div>
            <div>
              <h1
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  fontSize: 16,
                  fontWeight:"bold"
                }}
              >
                {project.property_name}
              </h1>
              <h1
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  fontSize: 16,
                  fontWeight:"bold"
                }}
              >
                in {project.city} with {project.room}Room upto {project.guests}
                Guests
              </h1>
              <h1
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight:"bold"
                }}
              >
                {project.distance}
              </h1>
              <h1
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight:"bold"
                }}
              >
                Starts from ₹{project.price}/Night
              </h1>

              <h1
                style={{
                  marginTop: 5,
                  fontSize: 18,
                  color: "grey",
                  backgroundColor: "transparent",
                }}
              >
                {project.state},{project.country}
              </h1>
            </div>
          </div>
        ))}
      </div>

      <div
       className="w-full max-w-7xl mx-auto m-10"
      >
        <h1 style={{
          color:"white",
          fontSize:"24px",
          fontWeight:"bold",
          
        }}>
          <u>Step into your homes of current owners </u>
        </h1>
      <div
        className="w-full max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-4"
        style={{
          // width: "100vw",
          // paddingLeft:"10vw",
          // paddingRight:"10vw",
          gap: "20px",
        }}
      >
        {luxuryList1.map((project, index) => (
          <div
            className="w-full justify-self-stretch h-auto"
            style={{
              padding: "5px",
            }}
            //onClick={() => HandleClick(project.slug)}
            key={index}
          >
            <img
              style={{
                borderRadius: "10px",
                objectFit: "cover",
                height: "310px",
                width: "100%",
              }}
              src={project.cover_image}
            />
          </div>
        ))}
      </div>
      </div>
      
    </div>
    <Footer />
  </div>
  );
};

export default LuxuryList;
