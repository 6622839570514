import React, { useState, useContext } from "react";
import * as Select from "@radix-ui/react-select";

import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constant";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { addDays, format } from "date-fns";

import { Button } from "../../components/ShadCN/Button";
import {
  Minus,
  Plus,
  UserRound,
  MapPin,
  Search,
  CalendarRange,
} from "lucide-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./Popover/PopOverHelper";

import { DateRangePicker } from "react-date-range";

const SearchBarWithDropdown2 = ({
  locationaOptions,

  onDataReceived,
}) => {
  const [selectedCity, setSelectedCity] = useState("Bengaluru");
  const [config, setConfig] = useState("3BHK");
   const [selectedPrice, setSelectedPrice] = useState(20);

  const [isCityOpen, setCityOpen] = useState(false);
  const [isAdultsOpen, setAdultsOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    onDataReceived({
      text: searchQuery,
      filters: {
        property_type: [],
        property_status: [],
        city: [selectedCity],
        property_builder: [],
        bedroom: [config],
        property_price: selectedPrice,

        bath_rooms: [],
        balconies: [],
        door_facing: [],
        furnishing_status: [],
        parking: [],
      },
    });
  };

  const handleChange1 = async (value) => {
    localStorage.setItem("city", value);
    setSelectedCity(value);
  };

  const inputStyle = {
    width: "100%",
    fontSize: "0.875rem",
    color: "black",
  };

  const SelectItem = React.forwardRef(
    ({ children, className, ...props }, forwardedRef) => {
      // If you want to make this component more customizable you can use classnames and tailwind-merge library.
      return (
        <Select.Item
          className="flex items-center justify-between px-3 cursor-default py-2 duration-150 text-gray-600 data-[state=checked]:text-indigo-600 data-[state=checked]:bg-indigo-50 data-[highlighted]:text-indigo-600 data-[highlighted]:bg-indigo-50 data-[highlighted]:hover:text-indigo-600 data-[highlighted]:hover:bg-indigo-50 outline-none"
          {...props}
          ref={forwardedRef}
        >
          <Select.ItemText>
            <div
              style={
                {
                  // paddingLeft: 10,
                  // paddingRight: 10,
                  // paddingTop: 5,
                  // paddingBottom: 5
                }
              }
              className="pr-4 line-clamp-1 flex flex-row justify-center items-center "
            >
              {children}
            </div>
          </Select.ItemText>
          <div className="w-6">
            <Select.ItemIndicator>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>{" "}
            </Select.ItemIndicator>
          </div>
        </Select.Item>
      );
    }
  );

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7), // 7 days after the current date
      key: "selection",
    },
  ]);

  const [data, setData] = useState({
    adults: 1,
    children: 0,
    rooms: 0,
  });

  const handleData = (key, value) => {
    setData((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  return (
    <div
      style={{
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div
        style={{ width: "100%", backgroundColor: "#cadbf9" }}
        className="flex flex-col justify-center gap-y-4 gap-x-4 p-4 items-center bg-white md:flex-row border border-gray-300 rounded-md shadow-md max-w-7xl mx-auto"
      >
        <div className="flex w-full gap-y-2 gap-x-2 flex-col md:flex-row justify-center items-center ">
          <div
            className="w-full flex-1 py-2.5"
            style={{
              backgroundColor: "white",
              color: "#352c2c",

              borderRadius: 5,

              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "start",

              paddingLeft: 15,
            }}
          >
            <h1
              style={{
                fontFamily: "sans-serif, Poppins",
                fontSize: 12,
                color: "gray",
                fontStyle: "italic",
              }}
            >
              Where you looking?
            </h1>
            <div className="w-full flex flex-row justify-center items-center">
              <MapPin className="mr-2" />
              <Select.Root
                value={selectedCity}
                onValueChange={(value) => handleChange1(value)}
                open={isCityOpen} // Control the open state
                onOpenChange={(open) => setCityOpen(open)}
              >
                <div className="w-full  z-1000">
                  <Select.Trigger
                    style={{
                      // backgroundColor: "red",

                      marginRight: 8,
                      fontSize: 14,
                      fontFamily: "sans-serif, Poppins",
                      fontWeight: 550,
                      border: "none", // Remove any default border
                      outline: "none", // Remove any default outline
                      boxShadow: "none",
                      whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                      overflow: "hidden", // Hides overflow text that doesn't fit in the container
                      textOverflow: "ellipsis", // Displays ellipsis (...) for overflow text
                      // Ensures the element is treated as a block container
                    }}
                    className=" w-full inline-flex items-center justify-between pr-2 py-2 "
                  >
                    <Select.Value
                      placeholder="Select City"
                      className="bg-white"
                    />
                  </Select.Trigger>
                  <Select.Portal>
                    <Select.Content
                      position="popper"
                      avoidCollisions={false}
                      style={
                        {
                          // backgroundColor: "white",
                        }
                      }
                      className="w-[80vw] sm:w-full md:w-[15vw] z-10 mt-3 overflow-y-auto bg-white border rounded-lg shadow-sm text-sm"
                    >
                      <Select.Viewport>
                        {[
                          { id: 1, name: "Bhubaneswar" },
                          { id: 2, name: "Bengaluru" },
                        ].map((item, idx) => (
                          <SelectItem
                            style={{
                              backgroundColor: "transparent",
                            }}
                            key={idx}
                            value={item.name}
                          >
                            {item.name}
                          </SelectItem>
                        ))}
                      </Select.Viewport>
                    </Select.Content>
                  </Select.Portal>
                </div>
              </Select.Root>
              <div
                style={{
                  paddingRight: 8,
                }}
              >
                {isCityOpen ? (
                  <IoIosArrowDropup size={20} />
                ) : (
                  <IoIosArrowDropdown size={20} />
                )}
              </div>
            </div>
          </div>

          <div
            className="w-full flex-1 py-2.5"
            style={{
              backgroundColor: "white",
              color: "#352c2c",

              borderRadius: 5,

              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "start",

              // paddingLeft: 15,
            }}
            // className=" w-full flex flex-1  flex-col  py-2"
            // style={{
            //   backgroundColor: SECONDARY_COLOR,
            //   borderRadius: 5,
            //   backgroundColor: "white",
            //   color: "#352c2c",
            // }}
          >
            <h1
              style={{
                fontFamily: "sans-serif, Poppins",
                fontSize: 12,
                color: "gray",
                fontStyle: "italic",
                paddingLeft: 15,
              }}
            >
              When you looking?
            </h1>
            <div>
              <Popover>
                <PopoverTrigger>
                  <Button>
                    <CalendarRange />{" "}
                    {state[0]?.startDate ? (
                      state[0].endDate ? (
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "sans-serif, Poppins",
                            fontWeight: 550,
                          }}
                        >
                          {format(state[0].startDate, "LLL dd, y")} -{" "}
                          {format(state[0].endDate, "LLL dd, y")}
                        </span>
                      ) : (
                        format(state[0].startDate, "LLL dd, y")
                      )
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className=" w-auto p-0 " align="start">
                  <div className="block md:hidden">
                    <DateRangePicker
                      //className="hidden md:block"
                      onChange={(item) => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="vertical"
                    />
                  </div>
                  <div className="hidden md:block">
                    <DateRangePicker
                      //className="hidden md:block"
                      onChange={(item) => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="horizontal"
                    />
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <div
            className=" w-full flex flex-1 py-2"
            style={{
              borderRadius: 5,
              justifyContent: "space-between",
              alignItems: "start",
              flexDirection: "column",

              backgroundColor: "white",
              color: "#352c2c",
            }}
          >
            <h1
              style={{
                fontFamily: "sans-serif, Poppins",
                fontSize: 12,
                color: "gray",
                fontStyle: "italic",
                paddingLeft: 15,
              }}
            >
              Guests and rooms?
            </h1>
            <div className="w-full flex justify-between items-center">
              <Popover>
                <PopoverTrigger>
                  <Button>
                    <UserRound />
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "sans-serif, Poppins",
                        fontWeight: 550,
                      }}
                    >
                      {data.adults} {data.adults > 1 ? "adults" : "adult"}
                    </span>
                    |
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "sans-serif, Poppins",
                        fontWeight: 550,
                      }}
                    >
                      {data.children} children
                    </span>
                    |
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "sans-serif, Poppins",
                        fontWeight: 550,
                      }}
                    >
                      {data.rooms} {data.rooms > 1 ? "rooms" : "room"}
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="bg-white p-6 w-full ">
                  <div>
                    <div className="flex justify-between items-center gap-10">
                      <div
                        style={{
                          fontFamily: "sans-serif, Poppins",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        Adults
                      </div>
                      <div className="flex flex-row justify-center items-center border border-indigo-100 rounded-[6px] ">
                        <button
                          onClick={() =>
                            handleData(
                              "adults",
                              data.adults > 0 ? data.adults - 1 : 0
                            )
                          }
                          className="px-2 py-2 text-indigo-600 duration-150  rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Minus />
                        </button>
                        <div className="px-6">{data.adults}</div>
                        <button
                          onClick={() => handleData("adults", data.adults + 1)}
                          className="px-2 py-2 text-indigo-600 duration-150   rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Plus />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-10 mt-4">
                      <div
                        style={{
                          fontFamily: "sans-serif, Poppins",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        Children
                      </div>
                      <div className="flex flex-row justify-center items-center border border-indigo-100 rounded-[6px] ">
                        <button
                          onClick={() =>
                            handleData(
                              "children",
                              data.children > 0 ? data.children - 1 : 0
                            )
                          }
                          className="px-2 py-2 text-indigo-600 duration-150  rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Minus />
                        </button>
                        <div className="px-6">{data.children}</div>
                        <button
                          onClick={() =>
                            handleData("children", data.children + 1)
                          }
                          className="px-2 py-2 text-indigo-600 duration-150   rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Plus />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-10 mt-4">
                      <div
                        style={{
                          fontFamily: "sans-serif, Poppins",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        Rooms
                      </div>
                      <div className="flex flex-row justify-center items-center border border-indigo-100 rounded-[6px] ">
                        <button
                          onClick={() =>
                            handleData(
                              "rooms",
                              data.rooms > 0 ? data.rooms - 1 : 0
                            )
                          }
                          className="px-2 py-2 text-indigo-600 duration-150  rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Minus />
                        </button>
                        <div className="px-6">{data.rooms}</div>
                        <button
                          onClick={() => handleData("rooms", data.rooms + 1)}
                          className="px-2 py-2 text-indigo-600 duration-150   rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                        >
                          <Plus />
                        </button>
                      </div>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
              <div
                style={{
                  paddingRight: 8,
                }}
              >
                {isAdultsOpen ? (
                  <IoIosArrowDropup size={20} />
                ) : (
                  <IoIosArrowDropdown size={20} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={handleSearch}
          style={{ borderRadius: 7, backgroundColor: PRIMARY_COLOR }}
          className="w-full md:w-1/6  flex flex-row justify-center items-center text-white font-bold py-6 px-6 focus:outline-none focus:shadow-outline"
        >
          <Search color="white" size={26} />
          <label style={{ fontFamily: "sans-serif, Poppins", fontWeight: 550 }}>
            Search
          </label>
        </div>
      </div>
    </div>
  );
};

export default SearchBarWithDropdown2;
