import React from "react";
import NewVacationImage from "../../assets/images/NewVacation.png";
import BorderImage from "../../assets/images/RectangleTop.png";
import { FaStar } from "react-icons/fa";
import { PRIMARY_COLOR, PRIMARY_COLOR1, PRIMARY_COLOR2 } from "../../constant";
import { CgEnter } from "react-icons/cg";
import { BsFillFileEarmarkRichtextFill } from "react-icons/bs";
import { MdOutlineLuggage } from "react-icons/md";
import { IoCameraOutline } from "react-icons/io5";
import { TfiLocationPin } from "react-icons/tfi";
import BasicDateRangeCalendar from "./Calender";
import SearchBarWithDropdown2 from "../../components/ShadCN/SearchBarWIthDropdownStates2";

const NewVacation = () => {
  // const startValue: Date =new Date(new Date().getFullYear(),new Date().getMonth(),14);
  // const endValue: Date =new Date(new Date().getFullYear(),new Date().getMonth()+1,15);
  // const minDate: Date =new Date(new Date().getFullYear(),new Date().getMonth(),8);
  // const maxDate: Date =new Date(new Date().getFullYear(),new Date().getMonth()+1,20);
  const handleData = async (data) => {};
  return (
    <div>
      <div
        style={{
          fontWeight: "bold",
        //  backgroundColor: "black",
          //fontSize: "40px",
          color: "white",
        }}
        className="flex flex-col sm:hidden justify-center items-center w-full text-3xl"
      >
        <label>Forget Busy Work,</label>
        <label>Start Next Vacation</label>
      </div>
      <div
        style={{
          // width: "95vw",
          // marginTop:"10px",

          marginBottom: "80px",
          // paddingLeft: "10vw",
          position: "relative",
          // paddingRight: "10vw",
        }}
        className="w-full max-w-7xl mx-auto h-[80vh] md:h-[50vh]   mt-20 flex flex-row justify-between "
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            height: 300,
            flexDirection: "column",
            color: "white",
            position: "absolute",
            flex: 1,
            padding: 15,

            // paddingLeft: "65px",
            zIndex: 2,
            //backgroundColor:"white"
            // paddingLeft:"10vw",
            // paddingRight:"10vw"
          }}
        >
          <div
            style={{
              color: "black",
              // display: "flex",
              justifyContent: "space-evenly",

              // flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                //fontSize: "40px",
                color: "white",
                //display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
              className="hidden sm:flex  text-3xl sm:text-5xl"
            >
              Forget Busy Work,
              <br />
              Start Next Vacation
            </h1>

            <h1
              style={{
                color: PRIMARY_COLOR2,
                fontSize: "20px",
                // width: "40vw",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="hidden sm:block max-w-7xl"
            >
              We Provide what you need to enjoy your holidaywith family time to
              make another memorable moments
            </h1>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            right: 0,
            // backgroundColor:"black"
          }}
          className="w-[70%] sm:w-[60%] md:w-[40%]"
        >
          <div
            style={{
              display: "flex",
              // height: "400px",
              justifyContent: "center",
              position: "relative",
              right: 0,
              margin: "50px",
              border: "5px solid white",
              borderTopLeftRadius: "80px",
              borderTopRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              // top: 0,
            }}
            className="h-[30vh] sm:h-[40vh] md:h-[50vh]"
            // className="w-[50%] sm:w-[30%]"
          >
            <div
              style={{
                position: "absolute",
                right: 0,
                width: "100%",

                top: -40,
                left: -30,

                zIndex: 0,
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderTopLeftRadius: "80px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  //border:"1px solid white"
                }}
                className="h-[30vh] sm:h-[40vh] md:h-[50vh]"
                src={NewVacationImage}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            // top: 280,
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            width: "100%",
            padding: 10,
            flexDirection: "column",
          }}
          className="top-[150px] sm:top-[280px]"
        >
          <SearchBarWithDropdown2 />
          <div>
            <div
              className="flex justify-between "
              style={{
                //width: "700px",
                height: "60px",
                backgroundColor: PRIMARY_COLOR,
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                paddingTop: "20px",
                flex: 1,
                gap: 30,
              }}
            >
              <div>
                <a
                  href=""
                  style={{
                    color: "pink",
                    fontSize: "24px",
                  }}
                >
                  <MdOutlineLuggage />
                </a>
                <a
                  href=""
                  style={{
                    color: "white",
                    fontSize: "16px",
                  }}
                >
                  80,409 travelers
                </a>
              </div>
              <div>
                <a href="" style={{ color: "pink", fontSize: "24px" }}>
                  <IoCameraOutline />
                </a>
                <a
                  href=""
                  style={{
                    color: "white",
                    fontSize: "16px",
                  }}
                >
                  862 treasure
                </a>
              </div>
              <div>
                <a href="" style={{ color: "pink", fontSize: "24px" }}>
                  <TfiLocationPin />
                </a>
                <a
                  href=""
                  style={{
                    color: "white",
                    fontSize: "16px",
                  }}
                >
                  862 treasure
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVacation;
