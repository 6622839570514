import React from "react";
import Header from "../Reusablecomponents/Header";
import Footer from "../Reusablecomponents/Footer";
import { PRIMARY_COLOR } from "../../constant";
import vilaImg1 from "../../assets/images/listVialImg1.png";
import vilaImg2 from "../../assets/images/listvilaImg2.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FaRoute } from "react-icons/fa";
import icon1 from "../../assets/icons/Home.png";
import icon2 from "../../assets/icons/mapIcon.png";
import icon3 from "../../assets/icons/man.png";
import icon4 from "../../assets/icons/RupeesIcon.png";
import mapImage from "../../assets/images/map.png";

import OwnerHomeImg1 from "../../assets/images/OwnerHome1.png";
import OwnerHomeImg2 from "../../assets/images/OwnerHome2.png";
import OwnerHomeImg3 from "../../assets/images/OwnerHome3.png";
import OwnerHomeImg4 from "../../assets/images/OwnerHome4.png";
import commaIcon from "../../assets/icons/comma.png";

import OwnerHomeImg5 from "../../assets/images/OwnerHome5.png";
import OwnerHomeImg6 from "../../assets/images/OwnerHome6.png";
import OwnerHomeImg7 from "../../assets/images/OwnerHome7.png";
import OwnerHomeImg8 from "../../assets/images/OwnerHome8.png";
import OwnerHomeImg9 from "../../assets/images/OwnerHome9.png";
import OwnerHomeImg10 from "../../assets/images/OwnerHome10.png";

import DropDown from "./DropDown";
import Navbar from "../Reusablecomponents/Navbar";
export const COLOR1 = "#9CB5BB";
export const COLOR2 = "#47808E";

const ListYourVilla = () => {
  const ListYourVilla = [
    {
      cover_image: OwnerHomeImg5,
    },
    {
      cover_image: OwnerHomeImg6,
    },
    {
      cover_image: OwnerHomeImg7,
    },
    {
      cover_image: OwnerHomeImg8,
    },
    {
      cover_image: OwnerHomeImg9,
    },
    {
      cover_image: OwnerHomeImg10,
    },
  ];
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95vw",
        }}
      >
        <div
                className="w-full max-w-7xl mx-auto"

          style={{
            // paddingLeft: "10vw",
            // paddingRight: "10vw",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            backgroundColor: PRIMARY_COLOR,
            position: "relative",
            justifyContent: "center",
          }}
        >
          <div>
            <img
              className="w-full sm:w-full md:w-full lg:w-[full]"
              style={{
                //width: "70vw",
                height: "550px",
                position: "relative",
              }}
              src={vilaImg1}
              alt=""
            />
          </div>

          <div
                  // className="w-full max-w-7xl mx-auto"

            // className="w-full sm:w-full md:w-full lg:w-[70vw] "
            style={{
              backgroundColor: COLOR2,
              paddingLeft: "10vw",
              paddingRight: "10vw",
              padding: "10px",
              //width: "70vw",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Your Holiday Villa Can Be...
            </h1>
            <p
              className="w-full sm:w-full md:w-[30vw] lg-[20vw]"
              style={{
                //fontStyle: "italic",
                color: "white",
                fontSize: "10px",
                //width: "27vw",
              }}
            >
              An Idyllic destination. A lucrative source of income . An
              immaculate retreat. Join Us and become a valued Tarry homeowner
              today
            </p>
          </div>

          <div
            className="w-[50vw] sm:w-[45vw] md:w-[45vw] lg:w-[20vw] absolute top-0 right-0 mr-[0vw] sm:top-0 sm:right-0 sm:mr-[0vw] md:top-20 md:right-0 md:mr-[0vw]  lg:top-40 lg:right-0 lg:mr-[0vw] "
            style={{
              backgroundColor: COLOR1,
              display: "flex",
              flexDirection: "column",
              height: "auto",
              padding: "10px",
              //position: "absolute",
              //marginRight:"10vw",
              // top: 0,
              // right: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              <h1>LET'S GET STARTED</h1>
            </div>
            <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <i
                className="fas fa-user"
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "black",
                  fontSize:"16px"
                }}
              ></i>
              <input
                type="text"
                placeholder ="What's your name?"
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "30px",
                  borderRadius: "5px",
                  fontSize:"16px"
                }}
              />
            </div>
            <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <i
                className="fas fa-envelope"
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "black",
                  fontSize:"16px"
                }}
              ></i>
              <input
                type="text"
                placeholder="Enter your email address"
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "30px",
                  borderRadius: "5px",
                  fontSize:"18px"
                }}
              />
            </div>
            {/* <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <i
                className="fas fa-phone"
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "black",
                }}
              ></i>
              <input
                type="text"
                placeholder="Give us your phone number"
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "30px",
                  borderRadius: "5px",
                }}
              />
            </div> */}
            <div>
              {/* <label className="text-gray-600">
                Phone number
            </label> */}
              <div className="relative  text-gray-500 w-[full]  mb-[-20px]" >
                <div className="absolute inset-y-0 left-3 my-auto h-6 flex items-center border-r pr-2">
                  <select className="text-sm bg-white outline-none rounded-lg h-full">
                    <option>+91</option>
                    {/* <option></option>
                        <option>MR</option> */}
                  </select>
                </div>
                <input
                  type="number"
                  placeholder="Give us your phone number"
                  className="w-full pl-[4.7rem] pr-3 py-2 appearance-none bg-white outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                />
              </div>
                
            </div>

            {/* <div
              style={{
                padding: "5px",
                fontSize: "10px",
                backgroundColor: "white",
                display: "flex",
                height: "30px",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <i
                className="fas fa-phone"
                style={{
                  left: "10px",
                  color: "black",
                }}
              ></i>
              <DropDown />
            </div> */}

            <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <i
                className="fas fa-building"
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "black",
                  fontSize:"18px"
                }}
              ></i>
              <input
                type="text"
                placeholder="What type of propertise is it"
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "30px",
                  borderRadius: "5px",
                  fontSize:"18px"
                }}
              />
            </div>
            <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <i
                className="fas  fa-volume-high"
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "black",
                  fontSize:"18px"
                }}
              ></i>
              <input
                type="text"
                placeholder="Where did you hear about us?"
                style={{
                  width: "100%",
                  height: "45px",
                  paddingLeft: "30px",
                  borderRadius: "5px",
                  fontSize:"18px"
                }}
              />
            </div>
            <div
              style={{
                padding: "5px",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <textarea
                placeholder="Describe your villa here (e.g., I have a villa in Coorg)"
                style={{
                  width: "100%",
                  height: "90px",
                  paddingLeft: "10px",
                  borderRadius: "5px",
                  fontSize:"18px"
                }}
              />
            </div>
            <button
              style={{
                backgroundColor: "blue",
                padding: "5px",
                color: "white",
                borderRadius: "10px",
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>

        <div

          className="flex flex-col md:flex-row justify-center w-full max-w-7xl mx-auto "
          style={{
          
            // paddingTop: "40px",
            // backgroundColor:"black",
            marginTop: "30px",
          }}
        >
          <div
          className="w-full md:w-[45vw]"
            style={{
              display: "flex",
              height: "400px",
              // width:"50vw",
              backgroundColor: PRIMARY_COLOR,
              // justifyContent: "space-between",
              marginRight: "3vw",
            }}
          >
            <img
            className="w-full md:w-[45vw]"
             style={{
              objectFit:"cover"
            }} src={vilaImg2} alt="" />
          </div>

          <div
            className="w-[full] sm:w-full md:w-[45vw]"
            style={{
              display: "flex",
              height: "auto",
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              //paddingLeft: "5px",
              // justifyContent: "space-between",
              // justifyContent: "center",
            }}
          >
            <div
              style={{
                color: COLOR1,
                fontSize: "16px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              We possess expertise in both luxury and hospitality,and we excel
              at blending these element to craft distinctive experiences for
              both travellers and homeowners
            </div>

            <div
              style={{
                color: COLOR1,
                fontSize: "16px",
              }}
            >
              Only the most exceptional residences earn a place on our roster.
              We meticulously curate each home, rigorously evaluating its
              adherence to our exacting criteria. Those that earn the
              distinction of being Vista homes are exquisite, opulent, and
              maintained to perfection, often owned by prominent business
              leaders, celebrities, and high-net-worth individuals (HNIs).
            </div>

            <div
              style={{
                color: COLOR1,
                fontSize: "16px",
              }}
            >
              Become a part of Tarry and transform your villa into a
              hassle-free, high-income, one-of-a-kind destination for the
              discerning traveler.
            </div>
          </div>

        </div>
      </div>
      <div
        className="flex flex-col sm:flex-row justify-center items-center m-10"
        style={{
          //  display: "flex",
          //  flexDirection: "row",
          backgroundColor: PRIMARY_COLOR,
          width: "auto",
          //justifyContent: "center",
          // marginTop: "45px",
          // marginBottom: "30px",
          gap: "5px",
          paddingLeft: "10vw",
          paddingRight: "10vw",
        }}
      >
        <div
          className=" sm:w-[40vw]   flex flex-col justify-center items-center"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <div
            className="w-[40vw] sm-[20vw]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <img
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
              src={icon1}
              alt=""
            />
            <h1
              style={{
                color: "white",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              500+
            </h1>
            <h1
              style={{
                color: "white",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              HandPicked Villas
            </h1>
          </div>

          <div
            className="w-[40vw] sm-[20vw]"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <img
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
              src={icon2}
              alt=""
            />
            <h1
              style={{
                color: "white",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              50+
            </h1>
            <h1
              style={{
                color: "white",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Spectacular destination
            </h1>
          </div>
        </div>

        <div
          className=" sm:w-[40vw]   flex flex-col justify-center items-center"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <div
            className="w-[40vw] sm-[20vw]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <img
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
              src={icon3}
              alt=""
            />
            <h1
              style={{
                color: "white",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              30,00,000+
            </h1>
            <h1
              style={{
                color: "white",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Delighted Guests
            </h1>
          </div>

          <div
            className="w-[40vw] sm-[20vw]"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <img
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
              src={icon4}
              alt=""
            />
            <h1
              style={{
                color: "white",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              500+
            </h1>
            <h1
              style={{
                color: "white",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Worth Homes Managed
            </h1>
          </div>
        </div>
      </div>

      <div
        className="w-full max-w-7xl mx-auto"
        style={{
          marginTop: "70px",
        }}
      >
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "24px",
          }}
        >
          <u>Benefits of being Tarry homeowner</u>
        </h1>
        <div
          className="flex justify-center flex-col md:flex-row "
          style={{
            height: "auto",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",

              flex: 1,
            }}
          >
            <img
              style={{
                objectFit: "cover",
              }}
              src={mapImage}
              alt=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
              alignItems: "center",
              // backgroundColor:"white"
            }}
          >
            <div
              className="flex flex-col sm:m-0 sm:flex-row justify-center items-center"
              style={{
                gap: "10px",
              }}
            >
              <div
                className="w-[80vw] sm:w-[40vw]  md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",

                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Seamless Maintenance
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  The time and effort you invest in the upkeep and servicing of
                  your home will see a significant reduction. We'll meticulously
                  manage every detail with unwavering attention.
                </p>
              </div>

              <div
                className="w-[80vw] sm:w-[40vw] md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Quadruple the income
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  Your expenses for maintenance will plummet to zero, and
                  through hosting guests, you'll generate a consistent revenue.
                </p>
              </div>
            </div>
            <div
              className="flex flex-col sm:m-0 sm:flex-row justify-center items-center"
              style={{
                gap: "10px",
              }}
            >
              <div
                className="w-[80vw] sm:w-[40vw]  md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",

                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Effortless Hosting
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  Welcoming guests into your home has become effortless, thanks
                  to the Tarry team taking care of everything, from customer
                  interactions to property administration.
                </p>
              </div>

              <div
                className="w-[80vw] sm:w-[40vw] md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Premium Access
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  You have the opportunity to explore our extensive network of
                  Tarry homes across the country. Enjoy luxurious travel
                </p>
              </div>
            </div>{" "}
            <div
              className="flex flex-col sm:m-0 sm:flex-row justify-center items-center"
              style={{
                gap: "10px",
              }}
            >
              <div
                className="w-[80vw] sm:w-[40vw]  md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",

                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Travel Flexibility
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  You have complete flexibility when it comes to enjoying and
                  visiting your own home. Reserve dates for your personal stays.
                </p>
              </div>

              <div
                className="w-[80vw] sm:w-[40vw] md:w-[20vw] lg:w-[15vw] flex flex-col justify-center items-center"
                style={{
                  backgroundColor: COLOR2,
                  padding: "5px",
                  height: "180px",
                }}
              >
                <h1
                  style={{
                    color: "white",

                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    paddingBottom: "5px",

                    textAlign: "center",
                  }}
                >
                  Your Home Is Your Retreat
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: COLOR1,
                    textAlign: "center",
                  }}
                >
                  The exquisite home you've established remains immaculate and
                  ready for your enjoyment, all without the accompanying chores.
                </p>
              </div>
            </div>
            <div
              className="flex flex-col sm:m-0 sm:flex-row justify-center items-center"
              style={{
                gap: "10px",
              }}
            ></div>
          </div>
        </div>
      </div>

      <div
        className="w-full max-w-7xl mx-auto"
        style={{
          marginTop: "60px",
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <u> What tarry will do for you and your home</u>
        </h1>
        <div
          style={{
            gap: "20px",
            height: "auto",
            marginTop: "10px",
          }}
        >
          <div
            className="w-full max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 p-4"
            style={{
              gap: "30px",
              // display:"flex",
              // flexDirection:"row"
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                //width: "35vw",
                //height: "350px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Maintain It
              </h1>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Keeping your home at 100%
              </h1>
              <p
                className="text-lg sm:text-base"
                style={{
                  color: "black",
                }}
              >
                We’ll help you ensure that your home’s maintenance needs, as
                well as the day to day management with vendors and staff is done
                on the clock, without any hassles.
              </p>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Protecting you and your home
              </h1>
              <p className="text-lg sm:text-base " style={{}}>
                Protecting you and your home Your home is our home. We’ll ensure
                that we’ll guard it (and you) against any damages caused by
                guests.
              </p>
            </div>

            <div
              style={{
                backgroundColor: "white",
                //width: "35vw",
                //height: "350px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Monitor It
              </h1>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                End-to-end solutions
              </h1>
              <p
                className="text-lg sm:text-base"
                style={{
                  color: "black",
                }}
              >
                From incoming enquiries to booking coordination to guest
                experiences to staff training and oversight, we’ve created a
                seamless process to handle all customer and staffing logistics.
              </p>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Finding the right guests
              </h1>
              <p className="text-lg sm:text-base " style={{}}>
                We seek and find travelers and guests with tastes that match
                yours. Every Vista guest is a patron of the good life, and is
                verified by us.
              </p>
            </div>
          </div>

          <div
            className="w-full max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 p-4"
            style={{
              gap: "30px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                //width: "35vw",
                //height: "350px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Market It
              </h1>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Making your home a destination
              </h1>
              <p
                className="text-lg sm:text-base"
                style={{
                  color: "black",
                }}
              >
                We market every Tarry home across different platforms to reach
                an audience far and wide and reach those travellers who are
                looking to make memories in your home.
              </p>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Finding your guests
              </h1>
              <p className="text-lg sm:text-base " style={{}}>
                By listing your home on our site as well as other platforms like
                Airbnb, GoIbibo, Agoda and Booking.com, we find you the guests
                and optimise your home’s hosting schedule.
              </p>
            </div>

            <div
              style={{
                backgroundColor: "white",
                //width: "35vw",
                //height: "350px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Monetise It
              </h1>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Increase the value
              </h1>
              <p
                className="text-lg sm:text-base"
                style={{
                  color: "black",
                }}
              >
                Through design consultations and interior upgrades, we guide you
                in getting the maximum value from your home.
              </p>
              <h1
                className="text-lg sm:text-lg"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Monetising your mansion
              </h1>
              <p className="text-lg sm:text-base " style={{}}>
                Through all our operations, your home goes from a prized asset
                and place of comfort to a steady stream of passive income.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full max-w-7xl mx-auto m-10"
        style={{
          marginTop: "60px",
        }}
      >
        <h1
          style={{
            color: "white",
            fontWeight: "bold",

            marginTop: "10px",
            fontSize: "24px",
          }}
        >
          <u>A word from our other homeowner</u>
        </h1>
        <div
          style={{
            paddingLeft: "10vw",
            paddingRight: "10vw",
            marginTop: "20px",
          }}
        >
          <div className="flex flex-col sm:m-0 sm:flex-row justify-center items-center">
            <div
              className="w-full sm:w-[30vw]   flex flex-col justify-center items-center"
              style={{
                // display: "flex",
                //flexDirection: "column",
                marginTop: "25px",
                justifyContent: "space-around",
                height: "300px",
                alignItems: "center",
                width: "30vw",
              }}
            >
              <div>
                <img
                  style={{
                    height: "70px",
                    borderRadius: "50px",
                    border: "2px solid white",
                  }}
                  src={OwnerHomeImg1}
                  alt=""
                />
              </div>

              <div>
                <img
                  style={{
                    height: "70px",
                    borderRadius: "50px",

                    border: "2px solid white",
                    marginLeft: "10vw",
                  }}
                  src={OwnerHomeImg2}
                  alt=""
                />
              </div>

              <div>
                <img
                  style={{
                    height: "70px",
                    borderTopRightRadius: "50px",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    borderBottomRightRadius: "50px",
                    border: "2px solid white",
                  }}
                  src={OwnerHomeImg3}
                  alt=""
                />
              </div>

              <div>
                <img
                  style={{
                    height: "70px",
                    borderTopRightRadius: "50px",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    borderBottomRightRadius: "50px",
                    marginLeft: "10vw",
                  }}
                  src={OwnerHomeImg4}
                  alt=""
                />
              </div>
            </div>

            <div
              className="w-[80vw] sm:w-[40vw]"
              style={{
                paddingTop: "10px",
              }}
            >
              <img
                style={{
                  paddingBottom: "10px",
                }}
                src={commaIcon}
                alt=""
              />
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                Tarry's service is exceptional, exceeding our expectations.
                Their guidance transformed our property into a gem.Transparency
                and technology set them apart.Grateful for our trusted
                partnership.
              </p>
              <p
                style={{
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Sidharth Villa | Coorg
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full max-w-7xl mx-auto"
        style={{
          marginTop: "60px",
        }}
      >
        <h1
          style={{
            color: "white",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          <u>Step into your homes of current owners </u>
        </h1>
        <div
          className=" w-full max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-4"
          style={{
            // width: "100vw",
            // paddingLeft:"10vw",
            // paddingRight:"10vw",
            gap: "20px",
          }}
        >
          {ListYourVilla.map((project, index) => (
            <div
              className="w-full justify-self-stretch h-auto"
              style={{
                padding: "5px",
              }}
              //onClick={() => HandleClick(project.slug)}
              key={index}
            >
              <img
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                  height: "310px",
                  width: "100%",
                }}
                src={project.cover_image}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ListYourVilla;
