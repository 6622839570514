import React from 'react'
// import livingimg1 from ""
import livingimg1 from "../../assets/images/listVialImg1.png"
import livingimg2 from "../../assets/images/LivingRoom2.png"
import livingimg3 from "../../assets/images/LivingRoom3.png"
import livingimg4 from "../../assets/images/LivingRoom4.png"
import livingimg5 from "../../assets/images/LivingRoom3.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { useNavigate } from "react-router-dom";
import "./SlickList.css";
import { PRIMARY_COLOR } from "../../constant";
import SlickSlider from "./SlickSlider";
const LivingRoomList=()=> {
  const LivingRoomList = [
    {
      cover_image: livingimg1,
      property_name: "Green Park",
      distance: "5km",
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: livingimg2,
      property_name: "Urvinkhan",
      distance: "7km",
      city: "Sakleshpura",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: livingimg3,
      property_name: "Silver Rain",
      distance: "4km",
      city: "Bangalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: livingimg4,
      property_name: "CashVilla",
      distance: "6km",
      city: "Chickmagaulr",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: livingimg5,
      property_name: "Silver Rain",
      distance: "6km",
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
  ];

  const showALL = async () => {
    // const queryParams = new URLSearchParams(filters ).toString();
    //navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const HandleClick = (slug) => {
    // window.open(`/${localStorage.getItem("city")}/property/${slug}`, "_blank");
    // localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  };

  return (
    <div
      style={{
        width:"95vw",
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: PRIMARY_COLOR,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
      }}
      className="py-10 sm:py-10"
    >
      {/* <h2
        style={{ fontSize: 40, marginTop: 40 }}
        className="text-center text-lg font-bold leading-none text-gray-900"
      >
        Featured Projects
      </h2> */}
      <div style={{
        display:"flex",
        justifyContent:"space-between",
         width:"100%",
         padding:"25px 5vw"
      }}>
      <h2
        style={{
          fontWeight: "bold",
          fontSize: 20,
          color: "white",
        }}
      >
        House with large living room
      </h2>
      <h2
        style={{
          fontWeight: 600,
          fontSize: 16,
          color: "white",
          
        }}
      >
        <u>Show All</u>
      </h2>
      </div>
      <div
         style={{
          display:"flex",
         justifyContent:"center",
         alignItems:"center",
         width:"90%",
         }}
      >
        <SlickSlider listData={LivingRoomList}/>

      </div>
    </div>
  )
}

export default LivingRoomList;