import React from "react";
import { PRIMARY_COLOR } from "../../constant";
import BlueOrigin from "../../assets/images/BlueOrginImage.png";
import OceanImage from "../../assets/images/OceanImage.png";
import StarkImage from "../../assets/images/StarkImage.png";
import VinnaImage from "../../assets/images/VinnaImage.png";
import Bobox from "../../assets/images/Bobox.png";

const MostPickedList = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: PRIMARY_COLOR,
        paddingLeft: "10vw",
        paddingRight: "10vw",
        marginTop:"150px",

      }}
    >
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 20,
          color: "white",

          display: "flex",
          
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        Most Picked
      </h1>
      <div
        className="flex flex-col sm:flex-row"
        style={{
          maxWidth: "100%",
          backgroundColor: PRIMARY_COLOR,
          // display: "flex",
          //justifyContent: "flex-start",
          //flexDirection: "row",
          height: "auto",
          gap: "10px",
          // paddingLeft: "10vw",
          // paddingRight:"10vw"
        }}
      >
        <div
          style={{
            display: "flex",
            height: "center",
            // width: "30vw",
            // paddingRight: "10px",
            // paddingRight:"10px",
            position: "relative",
          }}
        >
          <img
            className="w-full sm:w-[50vw] "
            style={{
              borderRadius: "10px",
              objectFit: "cover",
              //width: "100%",
              // height: "center",
            }}
            src={BlueOrigin}
            alt=""
          />
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              color: "white",
              textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              <h1>Blue Origin Fams</h1>
            </div>
            <div
              style={{
                fontSize: "10px",
                fontStyle: "italic",
              }}
            >
              <h1>Kundapur,India</h1>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",

            // width: "50vw",
          }}
        >
          <div
            className="flex flex-row sm:flex-row"
            style={{
              //display: "flex",
              //flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <div
              className="w-[45vw] h-[300px] sm:w-[25vw] sm:h-[280px] "
              style={{
                backgroundImage: `url(${OceanImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "Fill",
                backgroundSize: "cover",
                borderRadius: "10px",
                position: "relative",

                // width: "25vw",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  color: "white",
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <h1>Ocean Land</h1>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                  }}
                >
                  <h1>Coorg,India</h1>
                </div>
              </div>
            </div>

            <div
              className="w-[45vw] h-[300px] sm:w-[25vw] sm:h-[280px]"
              style={{
                backgroundImage: `url(${StarkImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "fill",
                backgroundSize: "cover",
                position: "relative",
                // width: "25vw",
                // height: "200px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  color: "white",
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <h1>Stark House</h1>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                  }}
                >
                  <h1>Asagoa,Goa</h1>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <div
              className="w-[45vw] h-[300px] sm:w-[25vw] sm:h-[280px]"
              style={{
                backgroundImage: `url(${VinnaImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                position: "relative",
                //width: "25vw",
                backgroundSize: "cover",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  color: "white",
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <h1>Vinna Vill</h1>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                  }}
                >
                  <h1>Dandeli,Karnataka</h1>
                </div>
              </div>
            </div>
            <div
              className="w-[45vw] h-[300px] sm:w-[25vw] sm:h-[280px]"
              style={{
                backgroundImage: `url(${Bobox})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                position: "relative",
                //width: "25vw",
                backgroundSize: "cover",
                //height: "200px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  color: "white",
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <h1>Bobox</h1>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                  }}
                >
                  <h1>Manali,Himachala</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPickedList;