import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import autoprefixer from 'autoprefixer';

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider() {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const marks = [
    {
      value: 10000,
      label: '10k',
    },
 
    {
      value: 50000,
      label: '50k',
    },
    {
        value: 100000,
        label: '100k',
      },
  ];

  return (
    <Box sx={{ width: autoprefixer }}>
      <Slider
        getAriaLabel={() => 'Price'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        color='white'
        marks={marks}
        step={10000}
        min={5000}
        max={100000}
        getAriaValueText={valuetext}
        sx={{
            '& .MuiSlider-markLabel': {
              color: 'white',  // Set the mark label text color
            },
          }}
      />
    </Box>
  );
}

