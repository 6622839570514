import React from "react";
import Slider from "react-slick";

const SlickSlider = (props) => {
  const list = props.listData;
  
  var settings = {
    //dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      // className="w-full max-w-7xl mx-auto"
      style={{
        width: "100%",

      }}
    >
      <Slider {...settings}>
        {list.map((project, index) => (
          <div
           
            style={{
              padding: "5px",
              margin:"0px 10px",
              display:"flex",
              flexDirection:"column",
              //width:"20vw"
              
            }}
            // onClick={() => HandleClick(project.slug)}
            key={index}
          >
           <div style={{
            margin:"10px",
            
           }}>
           <div style={{
                height:"150px",   
            }}>
              <img
                style={{
                  borderRadius: "10px",
                  objectFit:"cover",
                  height:"150px",
                  width:"100%"
                }}
                src={project.cover_image}
                alt={project.property_name}
              />
            </div>
            <div>
              <h1
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  marginTop:"1px",
                  fontSize: 16,
                }}
              >
                {project.property_name}
              </h1>
              <h1
                style={{
                  color: "white",
                  fontSize: 12,
                }}
              >
                {project.distance}
              </h1>
              <h1
                style={{
                  marginTop: 5,
                  fontSize: 12,
                  color: "grey",
                  backgroundColor: "transparent",
                }}
              >
                {project.city}, {project.state},{project.country}
              </h1>
            </div>
           </div>
          </div>
        ))}

      </Slider>
    </div>
  );
};

export default SlickSlider;
