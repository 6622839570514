import React from "react";
import Header from "../Reusablecomponents/Header";
import Footer from "../Reusablecomponents/Footer";
import BeautyBackyardList from "./BeautyBackyardList";
import LivingRoomList from "./LivingRoomList";
import KitchenRoomList from "./KitchenRoomList";
import HappyFamily from "./HappyFamily";
import NewVacation from "./NewVacation";
import MostPickedList from "./MostPickedList";
import Navbar from "../Reusablecomponents/Navbar";
import { PRIMARY_COLOR } from "../../constant";

const Homepage = () => {
  return (
    <div style={{
      backgroundColor:PRIMARY_COLOR,
    }}>
      <Navbar />
      <NewVacation /> 
      <MostPickedList /> 
      <BeautyBackyardList />
      <LivingRoomList />
      <KitchenRoomList /> 
       {/* <HappyFamily /> */}
      <Footer />
    </div>
  );
};

export default Homepage;
