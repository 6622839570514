import React from "react";
import Header from "../Reusablecomponents/Header";
import Footer from "../Reusablecomponents/Footer";
import DetailImg1 from "../../assets/images/DetailsImg1.png";
import DetailImg2 from "../../assets/images/DetailsImg2.png";
import DetailImg3 from "../../assets/images/DetailsImg3.png";
import { PRIMARY_COLOR, PRIMARY_COLOR2 } from "../../constant";
import { FaMinus } from "react-icons/fa";
import { HiPlusSm } from "react-icons/hi";
import { FaStar } from "react-icons/fa";
import DetailImg4 from "../../assets/images/DetailsImg4.png";
import DetailImg5 from "../../assets/images/DetailsImg5.png";
import DetailImg6 from "../../assets/images/DetailsImg6.png";
import DetailImg7 from "../../assets/images/DetailsImg7.png";
import DetailImg8 from "../../assets/images/DetailsImg8.png";
import Borderimage from "../../assets/images/RectangleButtom.png";
import SlickSlider from "../Homepage/SlickSlider";
import BedIcon from "../../assets/icons/AminityBed.png";
import { useState } from "react";
import SlickSlider2 from "./SlickSlider";
import Navbar from "../Reusablecomponents/Navbar";
import LeftImg from "../../assets/images/leftImg.avif";
import RightImg from "../../assets/images/rightImg.avif";
import { SprayCan } from "lucide-react";
import { CircleCheck } from "lucide-react";
import { MessageSquare } from "lucide-react";
import { MapPinned } from "lucide-react";
import { Tag } from "lucide-react";
import { KeyRound,BriefcaseBusiness } from "lucide-react";
import SlickRating from "./slickRating";
import HostImg from "../../assets/images/HappyImage.png";
import { FaUser } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
const Detailspage = () => {
  const DetailList = [
    {
      cover_image: DetailImg4,
      Type: "Seight Seeing",
      category: "Nature",
    },
    {
      cover_image: DetailImg5,
      Type: "Relaxation",
      category: "Pool",
    },
    {
      cover_image: DetailImg6,
      Type: "Go to the shops",
      category: "Shopping",
    },
    {
      cover_image: DetailImg7,
      Type: "Snorkeling",
      category: "Beach",
    },
    {
      cover_image: DetailImg4,
      Type: "Seight Seeing",
      category: "Nature",
    },
  ];
  const amenityList = [
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 1,
      amenityType: "living room",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 3,
      amenityType: "bathroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 1,
      amenityType: "dining room",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 10,
      amenityType: "mbp/s",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 7,
      amenityType: "unit ready",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 2,
      amenityType: "refigrator",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 4,
      amenityType: "television",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
    {
      amenityImage: BedIcon,
      amenityquantity: 5,
      amenityType: "bedroom",
    },
  ];
  const RatingList = [
    {
      Type: "Cleanliness",
      rating: 5.0,
      rating_image: <SprayCan size="40px" />,
    },
    {
      Type: "Accuracy",
      rating: 5.0,
      rating_image: <CircleCheck size="40px" />,
    },
    {
      Type: "Check-in",
      rating: 5.0,
      rating_image: <KeyRound size="40px" />,
    },
    {
      Type: "Communication",
      rating: 5.0,
      rating_image: <MessageSquare size="40px" />,
    },
    {
      Type: "Location",
      rating: 4.9,
      rating_image: <MapPinned size="40px" />,
    },
    {
      Type: "Value",
      rating: 4.9,
      rating_image: <Tag size="40px" />,
    },
  ];
  const [showAll, setShowAll] = useState(false);
  const displayedAmenities = showAll ? amenityList : amenityList.slice(0, 8);
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Navbar />
      <div
        style={{
          backgroundColor: PRIMARY_COLOR,
          paddingLeft: "10vw",
          paddingRight: "10vw",
          width: "95vw",
        }}
      >
        <div
          className="flex flex-col sm:flex-col md:flex-row"
          style={{
            gap: "10px",
          }}
        >
          <div>
            <img
              className="w-full sm:w-full md:w-[40vw] h-auto sm:h-[120vh] md:h-[700px]"
              style={{
                //height: "700px",
                borderRadius: "20px",
                objectFit: "cover",
              }}
              src={DetailImg1}
              alt=""
            />
          </div>

          <div
            className="flex flex-col sm:flex-col md:flex-col"
            style={{
              gap: "15px",
              height: "auto",
            }}
          >
            <img
              className="w-[full] sm:w-[full] md:w-[40vw]"
              style={{
                height: "340px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
              src={DetailImg2}
              alt=""
            />
            <img
              className="w-[full] sm:w-[full] md:w-[40vw]"
              style={{
                // width: "38vw",
                height: "340px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
              src={DetailImg3}
              alt=""
            />
          </div>
        </div>

        <div
          className="flex flex-col md:flex-row"
          style={{
            // width: "80vw",
            height: "auto",
            marginTop: "30px",
            gap: "20px",
          }}
        >
          <div
            className="flex flex-col w-[auto] md:w-[40vw]  lg:w-[50vw]"
            style={{
              gap: "20px",
              padding: "10px",
            }}
          >
            <h1
              style={{
                color: PRIMARY_COLOR2,
                fontSize: "20px",
              }}
            >
              Minimal techno is a minimalist subgenre of techno music. It is
              characterized by a stripped-down aesthetic that exploits the use
              of repetition and understated development. Minimal techno is
              thought to have been originally developed in the early 1990s by
              Detroit-based producers Robert Hood and Daniel Bell.
            </h1>
            <h1
              style={{
                color: PRIMARY_COLOR2,
                fontSize: "20px",
              }}
            >
              Such trends saw the demise of the soul-infused techno that
              typified the original Detroit sound. Robert Hood has noted that he
              and Daniel Bell both realized something was missing from techno in
              the post-rave era.
            </h1>
            <h1
              style={{
                color: PRIMARY_COLOR2,
                fontSize: "20px",
              }}
            >
              Design is a plan or specification for the construction of an
              object or system or for the implementation of an activity or
              process, or the result of that plan or specification in the form
              of a prototype, product or process. The national agency for
              design: enabling Singapore to use design for economic growth and
              to make lives better.
            </h1>
            {/* <div>
            <div className="bg-background p-4 rounded-lg">
              <div className="grid grid-cols-4 gap-4">
                <div className="flex flex-col items-center">
                  <img
                    alt="bedroom"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🛏️"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>5</span>{" "}
                    <span style={{ color: "grey" }}>bedroom</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="living room"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🛋️"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>1</span>{" "}
                    <span style={{ color: "grey" }}>living room</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="bathroom"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🚿"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>3</span>{" "}
                    <span style={{ color: "grey" }}>bathroom</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="dining room"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🍽️"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>1</span>{" "}
                    <span style={{ color: "grey" }}>dining room</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="internet speed"
                    src="https://openui.fly.dev/openui/24x24.svg?text=📶"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>10</span>{" "}
                    <span style={{ color: "grey" }}>mbp/s</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="unit ready"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🏢"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>7</span>{" "}
                    <span style={{ color: "grey" }}>unit ready</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="refrigerator"
                    src="https://openui.fly.dev/openui/24x24.svg?text=🧊"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>2</span>{" "}
                    <span style={{ color: "grey" }}>refigrator</span>
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    alt="television"
                    src="https://openui.fly.dev/openui/24x24.svg?text=📺"
                  />
                  <span className="mt-2">
                    <span style={{ color: "white" }}>4</span>{" "}
                    <span style={{ color: "grey" }}>television</span>
                  </span>
                </div>
              </div>
            </div>
          </div> */}

           <div style={{
            backgroundColor:"white"
           }}>
            <h1 style={{
              paddingLeft:"20px",
              fontSize:"20px",
              fontWeight:"bold"
            }}> <u>Amenities</u> </h1>
           <div 
            className="w-full  grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-3 p-4 bg-white"
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   flexWrap: "wrap",
              //   gap: "10px",
              //   alignItems: "center", // Align items vertically
              // }}
            >
              
              {displayedAmenities.map((project, index) => (
                <div
                  style={{
                    padding: "15px",
                    color: "white",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        src={project.amenityImage}
                        alt=""
                      />
                    </div>
                    <span
                      style={{
                        fontSize: "16px",
                        height: "40px",
                        color:"black",
                        display:"flex",
                        alignItems: "center",
                      }}
                    >
                      {project.amenityquantity} {project.amenityType}
                    </span>
                  </div>
                </div>
              ))}

              {/* Empty div to take up space and push button to the right */}
              <div style={{ flexGrow: 1 }} />

              {/* Button placed at the end of the row */}
              {amenityList.length > 8 && (
                <button
                  onClick={() => setShowAll(!showAll)}
                  style={{
                    color: "black",
                    // fontWeight:"bold",
                    marginTop: "10px",
                    marginLeft: "5px", // Pushes the button to the end of the line
                  }}
                >
                  <u>{showAll ? "See Less" : "See More"}</u>
                </button>
              )}
            </div>
           </div>
          </div>

          <div
            className="w-auto md:w-[40vw] lg:w-[30vw] "
            style={{
              
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                // height: "450px",
                position: "sticky",
                border:"1px solid white",
                button: 0,
                top: "130px" /* Adjust based on where you want it to stick */,
                zIndex: 10 /* Keeps it above other content */,
              }}
            >
              <div className=" p-4 rounded-lg shadow-md mx-auto w-full md-[40vw] lg:w-[30vw]  flex flex-col items-center justify-center">
                <h2 className="text-3xl  font-bold text-white mb-4 w-full md:w-[30vw] lg:w-[20vw] mt-4">
                  Start Booking
                </h2>

                <p className="text-xl text-white mb-2 w-full md:w-[30vw] lg:w-[20vw]">
                  <span className="text-green-300 text-3xl">Rs 15,000</span>{" "}
                  <span className="text-gray-500 text-2xl">per night</span>
                </p>

                <label className="block text-white mb-1 text-2xl w-full md:w-[30vw] lg:w-[20vw]">
                  How long you will stay?
                </label>

                <div className="w-full md:w-[30vw] lg:w-[20vw] flex items-center mb-4 mt-4 md:w-[21vw]">
                  <button className="bg-orange-500 text-gray-700  rounded-l-lg h-12 w-16 flex items-center justify-center">
                    <FaMinus />
                  </button>

                  <span className="bg-white text-sm h-12   w-full md:text-2xl  flex items-center justify-center ">
                    2 nights
                  </span>

                  <button className="bg-green-500 text-gray-700  rounded-r-lg h-12 w-16 flex items-center justify-center">
                    <HiPlusSm />
                  </button>
                </div>

                <label className="w-full md:w-[30vw] lg:w-[20vw] block text-white mb-1 text-2xl">
                  Pick a Date
                </label>

                <input
                  type="text"
                  className="w-full md:w-[30vw] lg:w-[20vw] border border-gray-300 rounded-lg p-2 w-[full] md:text-2xl md:w-[21vw] mb-4 flex text-center"
                  placeholder="20 Jan - 22 Jan"
                />

                <p className="w-full md:w-[30vw] lg:w-[20vw] text-gray-500 mb-4 text-l">
                  You will pay <span className="text-white">Rs 30,000</span> per{" "}
                  <span className="text-white">2 nights</span>
                </p>

                <button className="bg-blue-500 text-white w-full md:w-[30vw] lg:w-[20vw] p-2 rounded-lg md:text-2xl hover:bg-blue-400">
                  Continue to Book
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginBottom: "80px",
            marginTop: "50px",
          }}
        >
          <SlickSlider2 listData={DetailList} />
        </div>
        <div
          style={{
            marginBottom: "100px",
          }}
        >
          <div
            className="flex flex-col ml-14 sm:flex-row sm:w-[100%]"
            style={{
              //display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
              //width: "100%",
              // flexDirection: "row",
              //backgroundColor: PRIMARY_COLOR,
              color: "white",
              position: "relative",
              marginTop: "20px",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <div
              style={{
                height: "250px",
                width: 250,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "50px",
                position: "relative",
              }}
            >
              <div style={{}}>
                <div
                  style={{
                    position: "absolute",
                    top: -30,
                    left: -40,
                    zIndex: 0,
                  }}
                >
                  <img
                    style={{
                      marginTop: "4px",
                      height: "350px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "50px",
                    }}
                    src={Borderimage}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    zIndex: 1,
                    position: "absolute",
                  }}
                >
                  <img
                    style={{
                      height: "350px",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "10px",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "50px",
                    }}
                    src={DetailImg8}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                color: "black",
                display: "flex",
                width: "40vw",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "80px",
                // height:"350px"
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  height: "45px",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                Happy Family
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "yellow",
                }}
              >
                {[1, 2, 3, 4, 5].map((project, index) => (
                  <div
                    style={{
                      padding: "2px",
                    }}
                    key={index}
                  >
                    <FaStar />
                  </div>
                ))}
              </div>

              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  color: "white",
                }}
              >
                what a great trip with my family and i should try again next
                time soon....
              </h1>
              <h1
                style={{
                  fontSize: 14,
                  fontStyle: "italic",
                  color: "grey",
                }}
              >
                Raj,product Designer
              </h1>

              <div
                style={{
                  marginTop: "20px",
                  padding: "10px 15px",
                  backgroundColor: "blue",
                  width: "150px",
                  color: "white",
                  fontSize: 12,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                All Testimonils
              </div>

              {/* <div style={{
            display:'flex',
            flexDirection:"row"
        }}>
        <FaStar />
        <FaStar/>
        <FaStar/>
        <FaStar/>
        <FaStar/>
        </div> */}
            </div>
          </div>
        </div>
        <div
          className="w-full max-w-7xl mx-auto"
          style={{
            // backgroundColor: "white",
            marginTop: "150px",
            height: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "8vw",
                height: "20vh",
                // objectFit:"cover"
              }}
              src={LeftImg}
              alt=""
            />
            <h1
              style={{
                fontSize: "90px",
                fontWeight: "bold",
                display: "flex",
                color: "white",
                // alignItems: "center",
                // justifyContent:"start"
              }}
            >
              4.97
            </h1>
            <img
              style={{
                width: "8vw",
                height: "20vh",
              }}
              src={RightImg}
              alt=""
            />
          </div>

          <div>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Guest Favourite
            </h1>

            <p
              style={{
                //  display:"flex",
                //  justifyContent:"center",
                // fontSize:"18px",
                // textAlign:"center"
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
                color: "white",
              }}
            >
              This home is in the top 10% of eligible listings based on
              ratings,reviews and reliability.
            </p>
          </div>

          <div
            style={{
              marginBottom: "80px",
              marginTop: "50px",
            }}
          >
            {/* <SlickRating listData={RatingList} /> */}
            <div className="w-full  grid grid-cols-2 sm:grid-cols-3   xl:grid-cols-6 p-4 ">
              {RatingList.map((project, index) => (
                <div
                  style={{
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  // onClick={() => HandleClick(project.slug)}
                  key={index}
                >
                  {/* <div
              style={{
                // marginTop: "20px",
                // marginRight:"10px"
                height:"10vw"
              }}
            > */}

                  {/* <div>
                <h1
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    marginTop: "2px",
                    fontSize: 16,
                  }}
                >
                  {project.Type}
                </h1>

                <h1
                  style={{
                    color: "white",
                    fontSize: 16,
                  }}
                >
                  {project.rating}
                </h1>
              </div> */}

                  {/* <div
                style={{
                  height: "auto",
                }}
              >
                <img
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    height: "300px",
                    width: "100%",
                  }}
                  src={project.cover_image}
                />
              </div> */}
                  {/* </div> */}
                  <div>
                    <div>
                      <h1
                        style={{
                          color: "white",
                          fontSize: "20px",
                          paddingTop: "25px",
                        }}
                      >
                        <u>{project.Type}</u>
                      </h1>

                      <h1
                        style={{
                          color: "white",
                          fontSize: "20px",
                          paddingTop: "25px",
                        }}
                      >
                        {project.rating}
                      </h1>

                      <div
                        style={{
                          color: "white",
                          marginBottom:"20px",
                          paddingTop: "25px",
                        }}
                      >
                        {project.rating_image}
                      </div>
                    </div>

                    {/* <div style={{
            width:"1px",
            height:"7vw",
            backgroundColor:"white"
          }}>
          </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
        className="w-full max-w-7xl mx-auto "
        >
          <h1
            style={{
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
          >
            Meet your Host
          </h1>
          <div
          className="flex flex-col md:flex-row"
            style={{
              //display: "flex",
              //flexDirection: "row",
              height: "auto",
              // width:"80vw",
             gap:"50px",
              // justifyContent:"space-between"
              
            }}
          >
            <div 
            className="w-[full] md:w-[45vw]"
            style={{
              // backgroundColor:"black"
            }}
            >
              <div
              className="flex flex-row justify-around  w-[full] md:w-[35vw]"
                style={{  
                  height: "350px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  paddingTop: "20px",
                  gap:"50px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "8vw",
                    padding: "10px",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                <div style={{
                  width:"100px",
                  height:"100px"
                }}>
                <img
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "100px",
                      backgroundColor:"black",
                      objectFit:"cover"
                    }}
                    src={HostImg}
                    alt=""
                  />
                </div>
                  <h1
                    style={{
                      color: "black",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Siddhartha And Pavika
                  </h1>
                  <p
                    style={{
                      color: "black",
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "5px",
                    }}
                  >
                    <h1
                      style={{
                        paddingRight: "4px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                      }}
                    >
                      <FaUser />
                    </h1>
                    <h1>Superhost</h1>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    
                  }}
                >
                  <div>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      845 
                    </h1>
                    <h1
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Reviews
                    </h1>
                  </div>

                  <div
                  style={{
                    width:"auto",
                    backgroundColor:"black",
                    height:"1px"
                  }}
                  >
                  </div>
                  <div>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        display:"flex",
                        flexDirection:"row"
                      }}
                    >
                      <h1>4.96</h1>
                      <h1 style={{
                        fontSize:"12px",
                        paddingTop:"9px"
                      }}><FaStar /></h1>
                    </h1>
                    <h1 style={{
                      fontSize: "12px",
                    }}>Rating
                    </h1>
                  </div>

                  <div
                  style={{
                    width:"auto",
                    backgroundColor:"black",
                    height:"1px"
                  }}
                  >
                  </div>

                  <div>
                    <h1 style={{
                       fontSize: "20px",
                       fontWeight: "bold",
                    }}>3</h1>
                    <h1 style={{
                      fontSize:"12px"
                    }}>Years hosting</h1>
                  </div>
                </div>
                
              </div>

              <div
              className="w-full max-w-7xl mx-auto flex-col"
              >
             <div 
             style={{
              display:"flex",
              flexDirection:"row",
              gap:"10px",
              marginTop:"20px"
             }}>
             <h1 style={{
              color:"white",
             }}><BriefcaseBusiness /></h1>
             <h1 style={{
              color:"white",
              fontSize:"16px"
             }}>My work:Marketing-media & Saas/music - food Tech</h1>
             </div>
             <div style={{
              display:"flex",
              flexDirection:"row",
              marginTop:"10px",
              gap:"10px"
             }}>
              <h1 style={{
                color:"white",
                paddingTop:"4px"
              }}><GrLanguage /></h1>
              <h1 style={{
                color:"white",
                fontSize:"16px"
              }}>Speaks English and Hindi</h1>
             </div>
             <div>
              <h1 style={{
                color:"white",
                marginTop:"30px",
                marginBottom:"10px"
              }}>Hii, We are Siddharta and pavika - marketing professionals who moved to goa , away from the city...</h1>
              <u><a href="" style={{
                color:"white",
                fontSize:"20px",
                marginTop:"200px"
              }}>Show more</a></u>
             </div>
             </div>
            </div>
            <div 
            className="flex flex-col w-full md:w-[35vw]"
            style={{
             //width:"35vw",
             //display:"flex",
             //flexDirection:"column",
             gap:"10px",
             padding:"10px"
            }}>
              <h1 style={{
                color:"white",
                fontSize:"20px",
                fontWeight:"bold",
              }}>Siddhartha And Pavika is a Superhost</h1>
              <h1 style={{
                color:"white",
                fontSize:"16px"

              }}>Superhosts are experienced, highly rated hosts who are committed to providing great stays for guests</h1>
              <h1 style={{
                color:"white",
                fontSize:"20px",
                fontWeight:"bold",
                marginTop:"10px"
              }}>Co-Hosts</h1>
              <div style={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"10px"
              }}>
                <h1><img style={{
                  height:"50px",
                  width:"50px",
                  borderRadius:"50px"
                }} src={HostImg} alt="" /></h1>
                <h1 style={{
                  color:"white"
                }}>Pavika And Sidhhartha</h1>
              </div>
              <h1 style={{
                color:"white",
                fontSize:"20px",
                fontWeight:"bold",
                marginTop:"20px"
              }}>Host details</h1>
              <h1 style={{
                color:"white",
                marginTop:"10px"
              }}>Response rate: 100% <br />
              Responds within an hour
              </h1>
              <h1><button style={{
                 width:"auto",
                 height:"auto",
                 padding:"10px 20px",
                 borderRadius:"10px",
                 backgroundColor:"black",
                 color:"white",
                 fontSize:"16px",
                 marginTop:"10px"
              }}>Message Host</button></h1>
              <h1 style={{
                color:"white",
                fontSize:"16px"
              }}>Registration number: HOTN001912</h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Detailspage;
