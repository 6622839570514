import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { ProtectedRoute } from "./ProtectedRoute";
// import LoginPage from "../components/Auth/LoginPage";
import Homepage from "../components/Homepage/Homepage";
import LuxuryList from "../components/LuxuryCollection/LuxuryList";
import ListYourVilla from "../components/ListYourVilla/ListYourVilla";
import Contact from "../components/ContactPage/contactpage";
import Details from "../components/Details/Detailspage";
import Login from "../components/Reusablecomponents/Login";
import "../App.css";
import { PRIMARY_COLOR } from "../constant";
import Searchlist from "../components/SearchProject/SearchProjectPage"
const Routes = (props) => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/contact",
      element: <div>contact</div>,
    },
    {
      path: "/privacy-policy",
      element: <div>privacy</div>,
    },
    {
      path: "/terms-and-conditions",
      element: <div>turns</div>,
    },
    {
      path: "*",
      element: <div>notfound</div>,
    },
  ];

  // Define routes accessible only to authenticated users

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: (
            <div>
              <Homepage />
            </div>
          ),
        },
        {
          path: "/user",
          element: <div>user home page</div>,
        },

        // {
        //   path: "/user/notification",
        //   element: (
        //     <div>
        //       <div className="sticky top-0 bg-white w-full z-50">
        //         <Navbar />
        //       </div>
        //       <SideBar>
        //         <WishList />
        //       </SideBar>
        //       <Footer />
        //     </div>
        //   ),
        // },
        {
          path: "/luxury-list",
          element: (
            <div>
              <LuxuryList />
            </div>
          ),
        },
        {
          path: "/list-villa",
          element: (
            <div>
              <ListYourVilla />
            </div>
          ),
        },
        {
          path: "/contact-page",
          element: (
            <div>
              <Contact />
            </div>
          ),
        },
        {
          path: "/details-page",
          element: (
            <div>
              <Details />
            </div>
          ),
        },
        {
          path: "/searchlist",
          element: (
            <div>
              <Searchlist />
            </div>
          ),
        },
        {
          path: "/logout",
          element: <div>logout</div>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users

  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: (
        <div>
          <Homepage />
        </div>
      ),
    },

    {
      path: "/luxury-list",
      element: (
        <div>
          <LuxuryList />
        </div>
      ),
    },
    {
      path: "/list-villa",
      element: (
        <div>
          <ListYourVilla />
        </div>
      ),
    },
    {
      path: "/searchlist",
      element: (
        <div>
          <Searchlist />
        </div>
      ),
    },
    {
      path: "/contact-page",
      element: (
        <div >
         <Contact />
        </div>
      ),
    },
    {
      path: "/details-page",
      element: (
        <div>
          <Details />
        </div>
      ),
    },

    {
      path: "/login",
      element: <div>
        <Login/>
      </div>,
    },
    {
      path: "/signup",
      element: <div>signup</div>,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};
export default Routes;
