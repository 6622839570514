import React from "react";
import { PRIMARY_COLOR, PRIMARY_COLOR1, PRIMARY_COLOR2 } from "../../constant";
import { MdLocationOn } from "react-icons/md";
import { MdPhoneInTalk } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import React1, { useState } from "react";
import Navbar from "../Reusablecomponents/Navbar";
import Footer from "../Reusablecomponents/Footer";
const Contactpage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Step 2: Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Step 3: Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Additional form submission logic (e.g., API call)
  };
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Navbar />
     <div 
     style={{
     width: "95vw",
     height: "auto",
     }}> 
     <div
        style={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
          paddingTop:"20px",
          paddingBottom:"20px",
          marginLeft:"2vw",
          marginRight:"2vw",
          borderRadius:"10px",
        }}
      >
        <div
        className="w-full max-w-7xl mx-auto"
        >
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Contact Us
          </h1>
          <p
            style={{
              // width: "80vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            At Tarry, we're here to help you make the most of your experience
            with us. Whether you're looking for information, have questions, or
            need support, our team is ready to assist you. Reach out to us
            through any of the methods below, and we’ll get back to you as soon
            as possible.
          </p>
        </div>

        <div
        className="w-full max-w-7xl mx-auto flex flex-col md:flex-row"
          style={{
            marginTop: "80px",
            // width:"80vw"
            gap:"30px",
            
          }}
        >
        


          <div
          className=" w-full max-w-7xl flex py-20"
            style={{
             // width: "40vw",
             // display: "flex",
             // backgroundColor: PRIMARY_COLOR1,
              justifyContent: "center",
              height: "auto",
              border: "2px solid white",
              borderRadius:"10px",
              flex:1
            }}
          >
            <form action="">
              <h1
                style={{
                  fontSize: "24px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Send Message
              </h1>
              <label
                style={{
                  color: "white",
                  fontSize: "20px",
                }}
                htmlFor=""
              >
                Name:-
              </label>
              <input
              className="w-full sm:w-full md:w-full lg:w-[20vw]"
                style={{
                  //width: "20vw",
                  padding: "10px",
                  fontSize: "18px",
                  display: "flex",
                  borderRadius: "10px",
                  height:"auto"

                }}
                type="text"
                placeholder="Your name"
              />

              <label
                style={{
                  color: "white",
                  fontSize: "20px",
                }}
                htmlFor=""
              >
                Email:-
              </label>
              <input
              className="w-full sm:w-full md:w-full lg:w-[20vw]"
                style={{
                  //width: "20vw",
                  padding: "10px",
                  fontSize: "18px",
                  display: "flex",
                  borderRadius: "10px",
                  height:"auto"

                }}
                type="text"
                placeholder="Your email"
              />

              <textarea
              className="w-full sm:w-full md:w-full lg:w-[20vw]"
                style={{
                 // width: "20vw",
                  padding: "10px",
                  fontSize: "18px",
                  display: "flex",
                  borderRadius: "10px",
                  marginTop: "20px",
                  resize: "vertical",
                }}
                placeholder="Your message"
                rows={4}
              />

              <button
              className="w-full md:w-full md:w-full lg:w-[20vw]"
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  height: "40px",
                 // width: "20vw",
                  marginTop: "20px",
                  backgroundColor: "white",
                  color: PRIMARY_COLOR1,
                  borderRadius: "5px",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  marginBottom:"10px",
                  fontSize:"22px",
                }}
              >
                Submit
              </button>
            </form>

          </div>

          <div 
          className="flex justify-center items-start flex-col  "
            style={{
              flex:1,
              gap: "30px",
              padding:"20px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                backgroundColor:PRIMARY_COLOR1,
                width:"100%",
                padding:"20px"
              }}
            >
              <div
              
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50px",
                  fontSize: "40px",
                  
                }}
              >
                <MdLocationOn />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                >
                  <u>Address</u>
                </h1>
                <p
                  style={{
                    color: PRIMARY_COLOR2,
                    width: "10vw",
                    fontSize: "18px",
                  }}
                >
                  4671,BTM layout,Banglore
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                backgroundColor:PRIMARY_COLOR1,
                width:"100%",
                padding:"20px"
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50px",
                  fontSize: "30px",
                }}
              >
                <MdPhoneInTalk />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                >
                  <u>Contact</u>
                </h1>
                <p
                  style={{
                    color: PRIMARY_COLOR2,
                    width: "10vw",
                    fontSize: "18px",
                  }}
                >
                  +91 8884769695
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                backgroundColor:PRIMARY_COLOR1,
                width:"100%",
                padding:"20px"
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50px",
                  fontSize: "30px",
                }}
              >
                <MdEmail />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                >
                  <u>Email</u>
                </h1>
                <p
                  style={{
                    color: PRIMARY_COLOR2,
                    
                    fontSize: "18px",
                  }}
                >
                  info@homznoffiz.com
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
     </div>
      <Footer />
    </div>
  );
};

export default Contactpage;
