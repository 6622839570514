import React from "react";
import Slider from "react-slick";

const SlickSlider2 = (props) => {
  const detailList = props.listData;

  var settings = {
    //dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        
      },
    ],
  };
  return (
    <div
      className=""
      style={{
        width: "100%",
      }}
    >
      <Slider {...settings}>
        {detailList.map((project, index) => (
          <div
            style={{
              padding: "5px",
              margin: "0px 10px",
              display: "flex",
              flexDirection: "column",
              //width:"20vw"
            }}
            // onClick={() => HandleClick(project.slug)}
            key={index}
          >
            <div
              style={{
                marginTop: "20px",
                marginRight:"10px"
              }}
            >
              <div
                style={{
                  height: "auto",
                }}
              >
                <img
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    height: "300px",
                    width: "100%",
                  }}
                  src={project.cover_image}
                />
              </div>
              <div>
                <h1
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    marginTop: "2px",
                    fontSize: 16,
                  }}
                >
                  {project.Type}
                </h1>
                <h1
                  style={{
                    color: "white",
                    fontSize: 16,
                  }}
                >
                  {project.category}
                </h1>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider2;
