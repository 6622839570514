import React from "react";
import Logo from "../../assets/images/logo.png";
import { PRIMARY_COLOR } from "../../constant";
const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
        height: "auto",
        padding: "50px",
        display: "flex",
        flexDirection: "column",
     
        justifyContent: "space-evenly",
        alignItems: "center",
        width:"95vw",
     
      }}
    >
      <div 
      className="flex flex-col md:flex-row"
      style={
        {
           // display:"flex",
           // flexDirection:"row",
            gap:"20px",
            height: "100%",

          
        }
      }>
        <div
        className="flex flex-col justify-start items-start md:justify-center md:items-center"
          style={{
         
          }}
        >
          <img
            src={Logo}
            alt=""
            style={{
              height: 80,
            }}
          />
          <p
          className="text-start md:text-center p-0 md:p-6"
            style={{
 
              color:"#c6c6c6",
              fontSize: 12,
              // padding:"20px"
            }}
          >
            we kaboom your beauty holiday instantly and memorable
          </p>
        </div>


        <div
        className="flex flex-col justify-start items-start md:flex-row w-full md:w-[60vw]"
          style={{
 
             
             gap:"10px",
            fontFamily: "sans-serif, Poppins",
            
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100%",
              justifyContent: "center",
              alignItems: "start",
              padding: "0px",

              fontFamily: "sans-serif, Poppins",
              flexDirection: "column",
            }}
          >
            <h1 style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
              Luxury Villas
            </h1>

            <div
              style={{
                color: "#c6c6c6",
                fontSize: 12,
                display: "flex",
                //   backgroundColor:"white",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <a href="/">list a property</a>
              <a href="/">Book a Villa</a>
              <a href="/">Payments</a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100%",
              padding: "0px",

              justifyContent: "center",
              alignItems: "start",
              //   backgroundColor:"black",

              fontFamily: "sans-serif, Poppins",
              flexDirection: "column",
            }}
          >
            <h1 style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
              Explore Us
            </h1>

            <div
              style={{
                color: "#c6c6c6",
                fontSize: 12,
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <a href="/">Our Carrier</a>
              <a href="/">Privacy</a>
              <a href="/">Terms & Condition</a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100%",
              padding: "0px",

              justifyContent: "center",
              alignItems: "start",
              //   backgroundColor:"black",

              fontFamily: "sans-serif, Poppins",
              flexDirection: "column",
            }}
          >
            <h1 style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
              Connect Us
            </h1>

            <div
              style={{
                color: "#c6c6c6",
                fontSize: 12,
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <a href="/">Support @tarry.in</a>
              <a href="/">021-2208-1996</a>
              <a href="/">Tarry,Bengalore</a>
            </div>

          </div>

        </div>

      </div>
      <div style={{
         color:"#c6c6c6",
         fontSize:12,
         marginTop:"10px"
      }}>
        Copyright 2023.All rights reserved.Tarry
      </div>
    </div>
  );
};

export default Footer;
