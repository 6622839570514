export const PRIMARY_COLOR='#0A4C5C';
export const SECONDARY_COLOR = '#a2bef2';   

export const BLACK_COLOR='#000000';
export const WHITE_COLOR='#FFFFFF';
export const PRIMARY_COLOR1="#2F7282";
export const PRIMARY_COLOR2="#D9D9D9";
export const BASE_URL = 'https://agentnetwork.co.in/api';
//export const BASE_URL = 'http://localhost:3000/api';
export const IMAGE_PATH = 'https://agentnetwork.co.in/';
//export const IMAGE_PATH = 'http://localhost:3000/';
 
export const API_VERSION = 'v1';