import React from "react";
import Header from "../Reusablecomponents/Header";
import Footer from "../Reusablecomponents/Footer";
import { PRIMARY_COLOR } from "../../constant";
import SearchImage1 from "../../assets/images/LuxuryVila.png";
import SearchImage6 from "../../assets/images/LuxuryVila6.png";
import SearchImage8 from "../../assets/images/LuxuryVila8.png";
import SearchImage9 from "../../assets/images/OceanImage.png";
import SearchImage10 from "../../assets/images/LuxuryVila3.png";
import Search2Img1 from "../../assets/images/SearchList2Img1.jpeg";
import icon1 from "../../assets/icons/swimmer.png";
import icon2 from "../../assets/icons/grass.png";
import icon3 from "../../assets/icons/breakfast.png";
import icon4 from "../../assets/icons/wi-fi.png";
import icon5 from "../../assets/icons/barbeque.png";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { RiArrowDropDownLine } from "react-icons/ri";
import React1, { useState } from "react";
import RangeSlider from "./Slider";
import { CgEnter } from "react-icons/cg";
import { PRIMARY_COLOR1 } from "../../constant";
import Navbar from "../Reusablecomponents/Navbar";
import SlickSearch from "../SearchProject/SlickSearch"
const SearchProjectPage = () => {
  const SearchList = [
    {
      cover_image: SearchImage1,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage10,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage8,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage9,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage1,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage6,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage8,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage10,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage9,
      property_name: "Villa Corsa",
    },
    {
      cover_image: SearchImage10,
      property_name: "Villa Corsa",
    },
  ];
  const SearchList2 = [
    {
      cover_image: Search2Img1,
      property_name: "Kismet Villa",
      price: 22 + "," + 630,
      room: 4,
      guests: 4,
      Baths: 6,
      city: "Morjim",
      state: "Goa",
      country: "india",
    },
    {
      cover_image: Search2Img1,
      property_name: "Kismet Villa",
      price: 22 + "," + 630,
      room: 4,
      guests: 4,
      Baths: 6,
      city: "Morjim",
      state: "Goa",
      country: "india",
    },
    {
      cover_image: Search2Img1,
      property_name: "Kismet Villa",
      price: 22 + "," + 630,
      room: 4,
      guests: 4,
      Baths: 6,
      city: "Morjim",
      state: "Goa",
      country: "india",
    },
  ];
  const IconList = [
    {
      cover_image: icon1,
      property_name: "Swimming Pool",
    },
    {
      cover_image: icon2,
      property_name: "Lawn",
    },
    {
      cover_image: icon3,
      property_name: "Breakfast included",
    },
    {
      cover_image: icon4,
      property_name: "Wifi",
    },
    {
      cover_image: icon5,
      property_name: "BBQ Grill",
    },
  ];
  const CheckBoxList = [
    {
      Name: "Pool/jacuzzi",
    },
    {
      Name: "Pet Friendly",
    },
    {
      Name: "High Speed WiFi",
    },
    {
      Name: "Ideal for Large Groups",
    },
    {
      Name: "Private Lawn",
    },
    {
      Name: "BreakFast",
    },
    {
      Name: "Free Parking",
    },
    {
      Name: "Gym or fitness center",
    },
    {
      Name: "ChildCare",
    },
    {
      Name: "Taxi",
    },
  ];
  const [isVisible, setIsVisible] = useState(false);
  const [target, setTarget] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(target.checked);
  };
  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle between true and false
  };
  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };
  return (
    <div style={{
      backgroundColor:PRIMARY_COLOR,
    }}>
      <Navbar />
      <div
      className="flex flex-col sm:flex-row"
        style={{
          // display: "flex",
          // flexDirection: "row",
          backgroundColor: PRIMARY_COLOR,
          overflowX: "hidden",
          overflowY: "hidden",
          paddingLeft:"10vw",
          paddingRight:"10vw",
          width:"95vw"
        }}
      >
        <div
        className="hidden lg:block"
          style={{
            //display: "flex",
            flex: 1,
            //flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            width:"auto"
          }}
        >
          <div
            style={{
              width: "20vw",
              height: "110px",
              borderRadius: "10px",
             backgroundColor: PRIMARY_COLOR1,
              justifyContent:"start",
              // backgroundColor:"blue"

            }}
          >
            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "start",
                padding: "10px 20px",
                height:"auto"
              }}
            >
              <a href="">Price Range</a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                color: "white",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  paddingRight: "10px",
                }}
              >
                <a
                  style={{
                    border: "1px solid white",
                    width: "auto",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  href=""
                >
                  ₹ Min
                </a>
              </div>

              <div
                style={{
                  paddingRight: "10px",
                }}
              >
                <a
                  style={{
                    border: "1px solid white",
                    width: "auto",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  href=""
                >
                  ₹ Max
                </a>
              </div>

              <div
                style={{
                  paddingRight: "10px",
                }}
              >
                <a
                  style={{
                    border: "1px solid white",
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    padding:"10px"
                  }}
                  href=""
                >
                  Apply
                </a>
              </div>
            
            </div>
          </div>

          <div
            style={{
              width: "20vw",
              marginTop: "10px",
              height: "100px",
              borderRadius: "10px",
              backgroundColor: PRIMARY_COLOR1,
             
            }}
          >
            <div
              style={{
                color: "white",
                display: "flex",
                padding: "10px 20px",
              }}
            >
              <a href="">Rooms</a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                color: "white",
                //paddingLeft:"10px",
                flexDirection: "row",
              }}
            >
              <div>
                <h1>
                  <a href="">No.of Bedroom</a>
                </h1>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <a
                    href=""
                    style={{
                      border: "1px solid white",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "50px",
                      marginRight: "5px",
                      display:"flex",
                      justifyContent:"flex-end"
                    }}
                  >
                    <FiMinus />
                  </a>
                  <a
                    href=""
                    style={{
                      marginRight: "5px",
                      color: "white",
                      borderRadius: "50px",
                    }}
                  >
                    1
                  </a>
                  <a
                    href=""
                    style={{
                      border: "1px solid white",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "50px",
                    }}
                  >
                    <GoPlus />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "20vw",
              height: "auto",
              marginTop: "10px",
              color: "white",
              backgroundColor: PRIMARY_COLOR1,
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <a href=""> Top Filters</a>

              <div>
                {/* Button to toggle div visibility */}
                <button
                  style={{
                    paddingTop: "10px",
                  }}
                  onClick={toggleVisibility}
                >
                  {isVisible ? "" : ""} <RiArrowDropDownLine />
                </button>

                {/* Conditionally render the div based on isVisible state */}
              </div>
            </div>
            <div>
              {isVisible && (
                <div>
                  {CheckBoxList.slice(
                    0,
                    showMore ? CheckBoxList.length : 5
                  ).map((project, index) => (
                    <h1
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: "1px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "3px",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "5px",
                              paddingTop: "2px",
                            }}
                          >
                            <label>
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            </label>
                          </div>
                          <div>
                            <div>{project.Name} </div>
                          </div>
                        </div>
                      </div>
                    </h1>
                  ))}
                  {/* Button to toggle between 'Show More' and 'Show Less' */}
                  <button onClick={toggleShowMore}>
                   <u> {showMore ? "Show Less" : "Show More"}</u>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              width: "20vw",
              height: "auto",
              marginTop: "10px",
              color: "white",
              backgroundColor: PRIMARY_COLOR1,
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
              width:"auto",
              height:"auto"
              }}
            >
              <div>Price per Night</div>
            </div>
            <RangeSlider/>
          </div>

        </div>

        <div
        className="w-full sm:w-full md:w-full lg:w-[45vw] "
          style={{
            //display: "flex",
            //width: "60vw",
             //flex: 2,
            //flexDirection: "column",
            justifyContent: "end",
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          <div
            style={{
              color: "white",
            }}
          >
            <a href=""> Explore Our Luxurious Villas</a>
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* {SearchList.map((project, index) => (
              <div
                style={{
                  padding: "5px",
                }}
                //onClick={() => HandleClick(project.slug)}
                key={index}
              >
                <div
                  style={{
                    borderRadius: "32px",
                    border: "2px solid white",
                    padding: "3px",
                  }}
                >
                  <img
                    style={{
                      borderTopRightRadius: "50px",
                      borderTopLeftRadius: "50px",
                      borderBottomLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      height: "55px",
                      width: "55px",
                    }}
                    src={project.cover_image}
                    alt={project.property_name}
                  />
                </div>
                <div>
                  <h1
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      fontSize: 12,
                    }}
                  >
                    {project.property_name}
                  </h1>
                </div>
              </div>
            ))} */}
            <SlickSearch listData={SearchList}/>
            
          </div>

          <div >
            {SearchList2.map((project, index) => (
              <div
                style={{
                  padding: "5px",
                }}
                //onClick={() => HandleClick(project.slug)}
                key={index}
              >
                <div
                className="flex flex-col mb-5 md:flex-row"
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div>
                    <img
                    className="w-[70vw] h-[40vh]  md:w-[20vw] md:h-[20vh]"
                      style={{
                        borderRadius: "20px",
                       // width: "222px",
                        //height: "235px",
                      }}
                      src={project.cover_image}
                      alt={project.property_name}
                    />
                  </div>
                  <div>
                    <h1
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: 16,
                      }}
                    >
                      {project.property_name}
                    </h1>

                    <h1
                      style={{
                        marginTop: 5,
                        fontSize: 10,
                        color: "white",
                        backgroundColor: "transparent",
                      }}
                    >
                      {project.city},{project.state}
                    </h1>
                    <h1
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      Up to {project.guests} Guests ✦ {project.room} Rooms ✦{" "}
                      {project.Baths} Baths
                    </h1>
                    <div
                    className="flex flex-row md:justify-center"
                      style={{
                        //display: "flex",
                        //flexDirection: "row",
                        // justifyContent: "center",
                      }}
                    >
                      {IconList.map((project, index) => (
                        <div
                          style={{
                            padding: "5px",
                          }}
                          //onClick={() => HandleClick(project.slug)}
                          key={index}
                        >
                          <div
                            style={{
                              borderRadius: "30px",
                              //border: "2px solid white",
                              padding: "10px",
                              backgroundColor: "white",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "10px",
                                height: "25px",
                                width: "25px",
                              }}
                              src={project.cover_image}
                              alt={project.property_name}
                            />
                          </div>
                          <div>
                            <h1
                              style={{
                                backgroundColor: "transparent",
                                color: "white",
                                fontSize: 10,
                                width: "40px",
                                display: "flex",
                                justifyContent: "space-around",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              {project.property_name}
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h1
                      style={{
                        color: "white",
                        fontSize: 12,
                      }}
                    >
                      {project.distance}
                    </h1>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <h1
                              style={{
                                color: "white",
                                fontSize: 30,
                              }}
                            >
                              ₹{project.price}
                            </h1>
                          </div>
                          <div
                            style={{
                              color: "white",
                              fontSize: "10px",
                            }}
                          >
                            <a href="/"> Per Night + Taxes</a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            border: "2px solid white",
                            padding: "5px",
                            borderRadius: "20px",
                            color: "white",
                            width: "100px",
                            height: "40px",
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <a href="/">View Villa</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default SearchProjectPage;
