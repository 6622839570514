import React from "react";
import React1, { useState } from "react";
import { PRIMARY_COLOR } from "../../constant";
import Navbar from "./Navbar";
import Footer from "./Footer";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    // Add your login logic here, e.g., API call for authentication
    console.log("Email:", email);
    console.log("Password:", password);
    alert("Login successful");
  };
  return (
    <div style={{
      backgroundColor: PRIMARY_COLOR,
    }}>
      <Navbar />
      <div
        style={{
          width:"95vw",
          display: "flex",
          
          justifyContent: "center",
          height: "100vh", 
          alignItems: "center",
        }}
      >
        <div
        className="flex justify-center w-[full] sm:w-[60vw] md:w-[60vw]"
          style={{
            // border: "1px solid white",
            padding: "20px",
            margin: "20px",
            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.5)",
            height: "auto",
            borderRadius:"10px",
            display:"flex",
            justifyContent:"center"
          }}
        >
          <form action="">
            <h1
              style={{
                fontSize: "24px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                
              }}
            >
              Log in to your account
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: "18px",
                }}
              >
                Don't Have an account?
              </h1>
              <a href="#" style={{ color: "white" }}>
                <u>Sign up</u>
              </a>
            </div>
            <label
              style={{
                color: "white",
                fontSize: "20px",
              }}
              htmlFor=""
            >
              Username:-
            </label>

            <input
             className="flex w-full sm:w-full md:w-full lg:w-[20vw]"
              style={{
                
                padding: "10px",
                fontSize: "20px",
                
                borderRadius: "10px",
              }}
              type="text"
              placeholder="Your username"
            />

            <label
              style={{
                color: "white",
                fontSize: "20px",
              }}
              htmlFor=""
            >
              Password:-
            </label>
            <input
              className="flex w-full sm:w-full md:w-full lg:w-[20vw]"
              style={{
                //width: "20vw",
                padding: "10px",
                fontSize: "20px",
                //display: "flex",
                borderRadius: "10px",
              }}
              type="text"
              placeholder="Your password"
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <input type="checkbox" id="rememberMe" />
              <label
                htmlFor="rememberMe"
                style={{ color: "white", fontSize: "18px", marginLeft: "10px" }}
              >
                Remember Me
              </label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                color: "white",
                fontSize: "16px",
              }}
            >
              <a href="#" style={{ color: "white", textDecoration: "none" }}>
                <u>Forget Password?</u>
              </a>
            </div>

            <button
              style={{
                height: "40px",
                width: "20vw",
                marginTop: "20px",
                backgroundColor: "greenyellow",
                color: PRIMARY_COLOR,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
