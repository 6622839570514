import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { useNavigate } from "react-router-dom";
import "./SlickList.css";
import { PRIMARY_COLOR } from "../../constant";
import KitchenRoom1 from "../../assets/images/kitchenRoom1.png";
import KitchenRoom2 from "../../assets/images/kitchenRoom2.png";
import KitchenRoom3 from "../../assets/images/kitchenRoom3.png";
import kitchenRoom4 from "../../assets/images/kitchenRoom4.png";
import SlickSlider from "./SlickSlider";
const KitchenRoomList = () => {
  //const context = useContext(assetContext);
  //const { assetsfeatured, totalAssetsFeatured, getAssetsFeatured } = context;
  //const navigate = useNavigate();

  //   const [filters, setFilters]= useState({
  //     page: 1,
  //     property_type: [],
  //     property_status: [],
  //     property_locality: [],
  //     property_location:
  //       localStorage.getItem("city") !== null ? [localStorage.getItem("city")] : [],
  //     property_builder: [],
  //     property_bhks: [],
  //     status: [],
  //     property_starting_price: 0,
  //     property_maximum_price: 15,
  //     property_name: "",
  //     is_featured: 1,
  //     is_infocused: 0,
  //   });

  const kitchenList = [
    {
      cover_image: KitchenRoom1,
      property_name: "PS Wood",
      distance: "5km",
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: KitchenRoom2,
      property_name: "One Five",
      distance: "7km",
      city: "Bengalore",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: KitchenRoom3,
      property_name: "Minimal",
      distance: "4km",
      city: "morjim",
      state: "Goa",
      country: "india",
    },
    {
      cover_image: kitchenRoom4,
      property_name: "Stays Home",
      distance: "6km",
      city: "Coorg",
      state: "Karnataka",
      country: "india",
    },
    {
      cover_image: kitchenRoom4,
      property_name: "Stays Home",
      distance: "6km",
      city: "Coorg",
      state: "Karnataka",
      country: "india",
    },
  ];
  const showALL = async () => {
    // const queryParams = new URLSearchParams(filters ).toString();
    //navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const HandleClick = (slug) => {
    // window.open(`/${localStorage.getItem("city")}/property/${slug}`, "_blank");
    // localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  };

  return (
    <div
    style={{
      width:"95vw",
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: PRIMARY_COLOR,
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"
    }}
    className="py-10 sm:py-10"
  >
    {/* <h2
      style={{ fontSize: 40, marginTop: 40 }}
      className="text-center text-lg font-bold leading-none text-gray-900"
    >
      Featured Projects
    </h2> */}
    <div style={{
      display:"flex",
      justifyContent:"space-between",
       width:"100%",
       padding:"25px 5vw"
    }}>
    <h2
      style={{
        fontWeight: "bold",
        fontSize: 20,
        color: "white",
      }}
    >
      Apartments with kitchen set
    </h2>
    <h2
      style={{
        fontWeight: 600,
        fontSize: 16,
        color: "white",
        
      }}
    >
      <u>Show All</u>
    </h2>
    </div>
    <div
       style={{
        display:"flex",
       justifyContent:"center",
       alignItems:"center",
       width:"90%",
       }}
    >
      <SlickSlider listData={kitchenList}/>

    </div>
  </div>
  );
};

export default KitchenRoomList;
